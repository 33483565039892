export const getParams = function (activeName, date1, date2) {
  return {
    // subject_id: activeName,
    // to_compare_start_time: date1 ? date1[0] : '',
    // to_compare_end_time: date1 ? date1[1] : '',
    // be_compared_start_time: date2 ? date2[0] : '',
    // be_compared_end_time: date2 ? date2[1] : '',
    subjectId: activeName,
    thisTimeStart: date1 ? date1[0] : '',
    thisTimeEnd: date1 ? date1[1] : '',
    lastTimeStart: date2 ? date2[0] : '',
    lastTimeEnd: date2 ? date2[1] : '',
  }
}