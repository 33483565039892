import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import { instance, http_file } from '@/utils/http.js'
// import { no_loading } from '@/utils/no_loading.js'
import basicUrl from '@/utils/basicUrl'
import getFile from './utils/file'
// import './utils/directives'
import { rands } from '@/utils/rand.js'
import Pagination from '@/views/components/Pagination'
import * as echarts from 'echarts';

import 'video.js/dist/video-js.css' //视频插件样式
// 引入公共样式
import './statics/index.scss'
import Video from 'video.js'
import mitt from 'mitt'
Vue.prototype.$EventBus = new mitt()
Vue.prototype.$video = Video //引入Video播放器

Vue.prototype.$echarts = echarts
Vue.prototype.$rands = rands

import getMd5Form from '@/utils/getMd5Form.js'
Vue.prototype.$getMd5Form = getMd5Form

Vue.component('pagination', Pagination)
import { getParams } from './utils/report.js'

import TableLine from '@/views/chapter/components/line.vue'
Vue.component('TableLine', TableLine)

import '@/utils/rem.js'

import { Button, Form, Field, Toast, Icon, Picker, Popup, Cascader } from 'vant';
import 'vant/lib/index.css';
Vue.use(Button).use(Form).use(Field).use(Toast).use(Icon).use(Picker).use(Popup).use(Cascader);

Vue.prototype.$getTimeParams = getParams
import htmlToPdf from '@/utils/htmlToPdf'
// 引入的路径请根据自己的实际情况来写

Vue.use(htmlToPdf)



//选择文件功能
Vue.prototype.$get_file = getFile
// bsaic请求基底
Vue.prototype.$basicUrl = basicUrl
// 挂载请求
Vue.prototype.$http = instance
// 没有loading的请求规则
// Vue.prototype.$no_loading = no_loading
// 上传文件功能
Vue.prototype.$http_file = http_file
Vue.prototype.$checkArr = ['1', '2', '3', '4', "9", '13', '14', '15']

Vue.prototype.$subject_styles = {
  // 理科数学
  1: {
    text_color: '#44DDA9',
    subject_backg_color: '#E6FFF2'
  },
  //文科数学
  2: {
    text_color: '#44DDA9',
    subject_backg_color: '#E6FFF2'
  },
  //地理
  3: {
    text_color: '#FF954E',
    subject_backg_color: '#FFF1E6'
  },
  //物理
  4: {
    text_color: '#FF954E',
    subject_backg_color: '#FFF1E6'
  },
  //政治
  5: {
    text_color: '#FF654E',
    subject_backg_color: '#FFE9E6'
  },
  //历史
  6: {
    text_color: '#AD5FF6',
    subject_backg_color: '#F7E6FF'
  },
  //语文
  7: {
    text_color: '#557EF2',
    subject_backg_color: '#E4EAFD'
  },
  //化学
  9: {
    text_color: '#2196F3',
    subject_backg_color: '#E6F0FF'
  },
  //生物
  10: {
    text_color: '#37D5F9',
    subject_backg_color: '#E6FDFF'
  },
  //英语
  11: {
    text_color: '#F3CA60',
    subject_backg_color: '#FFF7E2'
  },
  //数学
  12: {
    text_color: '#44DDA9',
    subject_backg_color: '#E6FFF2'
  },
  //技术
  13: {
    text_color: '',
    subject_backg_color: ''
  },
}


// 拼接图片服务器地址
function addHeader (value) {
  return 'https://sunraytiku.oss-cn-beijing.aliyuncs.com/' + value
}
function addAllHeader (value) {
  if (value.indexOf('./') != -1) {
    value = value.substr(2, value.length - 1)
    return 'https://sunraytiku.oss-cn-beijing.aliyuncs.com/' + value
  }
  else if (value.indexOf('https://sunraytiku.oss-cn-beijing.aliyuncs.com/') == -1) {
    return 'https://sunraytiku.oss-cn-beijing.aliyuncs.com/' + value
  } else {
    return value
  }
}
function addForHeader (value) {
  if (!Array.isArray(value)) {
    let arr = []
    arr.push(value)
    value = arr
  }
  return value.map(item => {
    if (item.indexOf('./') != -1) {
      item = item.substr(2, item.length - 1)
      return 'https://sunraytiku.oss-cn-beijing.aliyuncs.com/' + item
    }
    else if (item.indexOf('https://sunraytiku.oss-cn-beijing.aliyuncs.com/') == -1) {
      return 'https://sunraytiku.oss-cn-beijing.aliyuncs.com/' + item
    }
    else {
      return item
    }
  })

}

function removePaperInfo () {
  window.sessionStorage.removeItem('paper_info')
  window.sessionStorage.removeItem('paper_list')
  window.sessionStorage.removeItem('image')
  window.sessionStorage.removeItem('analysis_paper')
}


// 正则拼接富文本的url前缀
function replaceAll (value) {
  if (!value) {
    return
  }
  var msg = 'src="/'
  return value.replace(new RegExp(msg, 'g'), `src="https://sunraytiku.oss-cn-beijing.aliyuncs.com/`)
}
Vue.prototype.$replaceAll = replaceAll
Vue.prototype.$addHeader = addHeader
Vue.prototype.$addAllHeader = addAllHeader
Vue.prototype.$addForHeader = addForHeader
Vue.prototype.$removePaperInfo = removePaperInfo

Vue.config.productionTip = false


Vue.directive('drag', {
  bind (el, binding) {
    let startX, startLeft, startRight;
    let stopDraggingflag = false; // 停止拖拽的标志变量

    el.addEventListener('mousedown', e => {
      startX = e.clientX;
      startLeft = parseInt(el.style.left) || 0;
      startRight = parseInt(el.style.right) || 0;

      document.addEventListener('mousemove', move);
      document.addEventListener('mouseup', stop);
    });

    function move (e) {
      if (stopDraggingflag) return; // 如果停止拖拽的标志为true，则直接返回，不执行拖拽操作

      const distance = e.clientX - startX;
      const left = startLeft + distance;
      const right = startRight - distance

      if (left >= 0) {
        el.style.left = '0px';
      } else if (left <= -90) {
        el.style.left = -90 + 'px';
      } else {
        el.style.left = left + 'px';
      }

    }

    function stop () {
      document.removeEventListener('mousemove', move);
      document.removeEventListener('mouseup', stop);

    }

  }
});








new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
