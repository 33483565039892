import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    subjectList: [],
    knowledageData: {},
    pointData: {}
  },
  getters: {},
  mutations: {
    SET_SUBJECT (state, payload) {
      if (payload && payload.length) {
        state.subjectList = payload
      }
    },
    CLEAR_SUBJECT (state, payload) {
      state.subjectList = []
    },
    SET_KNOWLEDGE (state, payload) {
      if (payload.data) {
        state.knowledageData = payload
      }
    },
    SET_POINT (state, payload) {
      if (payload.data) {
        state.pointData = payload
      }
    },
  },
  actions: {
  },
  modules: {
  }
})


export default store

