<template>
  <div id="app">
    <!-- notKeepPath 这个是在keep之后跳转的页面  点击返回的时候需要保存keep页面不被刷新 ，所有keep跳转之后的页面都需要添加到这里 -->
    <keep-alive v-if="notKeepPath.indexOf($route.path) ==-1 || $route.meta.keep">
      <router-view v-if="$route.meta.keep" />
    </keep-alive>
    <router-view v-if="!$route.meta.keep"></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {},
  data () {
    return {
      notKeepPath: ['/self_correct', '/analysis', '/discuss_info', '/doPaper']  //,
    }
  },
  mounted () {
    // let query = this.$route.query
    // if (query.token) {
    //   let userInfo = {
    //     token: query.token
    //   }
    //   window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
    // }
  },
  methods: {
    webviewBack () {
      if (this.$route.path == '/login') return
      if (this.$route.path == '/index') {
        this.$http({
          url: '/api/v1/user/logout',
          method: 'get'
        }).then(res => {
          window.sessionStorage.clear()
          this.$message.success('退出登录成功')
          this.$router.push('/login?not_auto_login=1')
        })
      } else {
        this.$router.go(-1)
      }
    },
  }

}
</script>

<style >
#app {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: initial;
  font-size: 12rem;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
div,
dd {
  margin: 0;
  padding: 0;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  border: none;
  vertical-align: top;
}
.el-message{
  font-size: 16px;
}
</style>
