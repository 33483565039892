import CryptoJS from 'crypto-js';

const getMd5Form = function (form, url) {
  form = JSON.parse(JSON.stringify(form))
  // console.log(JSON.parse(JSON.stringify(form)))
  let str = ''
  if (url && url.includes('/api/rpc/')) {

    form.api_key = 'A719n7UkTikur*6_@MhJ'
    console.log('11111111111111', form.api_key);

  } else {
    form.api_key = '0x$2M1Thiix27!8k';
  }




  let keys = Object.keys(form);
  keys.sort().forEach(item => {
    if (form[item] === null) {
      form[item] = ''
    }
    if (typeof form[item] == 'object') {
      if (JSON.stringify(form[item]) != '{}') {
        str += JSON.stringify(form[item])
      } else {
        str += JSON.stringify([])
      }
    }
    else {
      str += typeof form[item] != 'undefined' ? form[item] : '';
    }

  });
  // console.log(str)
  return CryptoJS.MD5(str).toString();
}

export default getMd5Form
